function PasswordForm({ password, setPassword, handleSubmit }) {
    return (
        <form onSubmit={handleSubmit} className="flex flex-col">
            <label htmlFor="password" className="mb-2">
                Password
            </label>
            <input
                id="password"
                type="password"
                value={password}
                defaultValue={""}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                required
                className="p-2 mb-4 border-2 border-gray-300 rounded"
            />
            <button type="submit" className="p-2 text-white bg-blue-500 rounded hover:bg-blue-700">
                Check Password
            </button>
        </form>
    );
}

export default PasswordForm;
