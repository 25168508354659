import create from "zustand";

const useStore = create((set) => ({
    data: null,
    loading: false,
    password: "",
    passwordSubmitted: false,
    error: null,
    checking: false,
    setData: (data) => set({ data }),
    setLoading: (loading) => set({ loading }),
    setPassword: (password) => set({ password }),
    setPasswordSubmitted: (passwordSubmitted) => set({ passwordSubmitted }),
    setError: (error) => set({ error }),
    setChecking: (checking) => set({ checking }),
}));

export default useStore;
