import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getCookie, setCookie } from "react-use-cookie";

import useStore from "../stores/reportStore";

import Report1 from "./templates/Report1";
import PasswordForm from "./PasswordForm";

const Loading = () => <p>Checking Report Status...</p>;
const Generating = () => <p>Report is generating. Please wait a moment.</p>;

function Report() {
    const { taskId } = useParams();
    const {
        data,
        loading,
        password,
        passwordSubmitted,
        error,
        checking,
        setData,
        setLoading,
        setPassword,
        setPasswordSubmitted,
        setError,
        setChecking,
    } = useStore(); // use the store

    // This is just for testing purposes. Cookie set should be actually in the main app.
    /*
    const setUserToken = () => {
        setCookie(
            "token_reporting",
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImIyZGY5NmMxLTJjZDYtNGYxMi05YzZhLWMzNzllMmQ0MGUyYiIsInJvbGUiOiJTdXBlciBhZG1pbiIsImVtYWlsIjoidmlnbmVzaC5waWxsYXlAZGF0YXplaXQuaW8iLCJpYXQiOjE2ODgzNzk2ODZ9.l3463DiXsE68CzvqlKoGF4mQugM2-fYNd0hMrf_Y1II",
            {
                days: 365,
                path: "/",
                domain: ".eyva.ai",
                secure: true,
                sameSite: "none",
            }
        );
    };

    setUserToken();

    */

    const fetchReport = () => {
        const url = `https://data-dev.eyva.ai/api/reports/get-report-test?password=${password}&task_id=${taskId}`;

        const jwtToken = getCookie("token_reporting"); // Replace 'jwt' with the actual cookie name

        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${jwtToken}`,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 401) {
                        throw new Error("Wrong password");
                    }
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then(({ data }) => {
                if (data.done) {
                    setData(data.data);
                    setLoading(false);
                } else {
                    setTimeout(fetchReport, 1000);
                    setChecking(false);
                }
            })
            .catch((error) => {
                if (error.message === "Wrong password") {
                    setError(error.message);
                    setChecking(false);
                } else {
                    console.error("Error:", error);
                    setPasswordSubmitted(false);
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        const jwtToken = getCookie("token_reporting"); // Replace 'jwt' with the actual cookie name

        console.log({ jwtToken });
        if (passwordSubmitted) {
            setLoading(true);
            setChecking(true);
            setError(null); // reset error when a new request is made
            fetchReport();
        }
    }, [taskId, passwordSubmitted]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setPasswordSubmitted(true);
    };

    return (
        <div className="flex flex-col items-center mx-auto my-12 space-y-12">
            <h1>Report {taskId}</h1>
            {!passwordSubmitted ? (
                <PasswordForm password={password} setPassword={setPassword} handleSubmit={handleSubmit} />
            ) : loading && checking ? (
                <Loading />
            ) : loading && !checking ? (
                <Generating />
            ) : error ? (
                <p>{error}</p>
            ) : (
                data && <Report1 data={data} />
            )}
        </div>
    );
}

export default Report;
