import { Routes, Route, Navigate } from "react-router-dom";

import ReportRouter from "./reports/Router";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/Test" replace />} />
            <Route path="/:taskId" element={<ReportRouter />} />
        </Routes>
    );
}

export default App;
