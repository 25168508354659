const colors = ["honeydew", "whitesmoke"]; // add more colors if you have more than two items

const KPIItem = ({ bgColor, data }) => (
    <div className={`bg-${bgColor} flex flex-row items-center justify-center gap-[10px]`}>
        <div className="relative inline-block w-[47px] h-[18px] shrink-0">KPI</div>
        <div className="relative inline-block w-[47px] h-[18px] shrink-0">{data}</div>
    </div>
);

const Slide = ({ data }) => (
    <div className="relative flex flex-col items-center justify-center w-full h-full text-xs text-center text-black bg-white font-inter">
        <div className="flex flex-col items-center justify-center gap-[10px]">
            {data.map((item, index) => (
                <KPIItem key={index} bgColor={colors[index % colors.length]} data={item.value} />
            ))}
        </div>
    </div>
);

export default Slide;
