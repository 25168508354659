import SlideFirst from "../../components/Report1/SlideFirst_API";
import SlideSecond from "../../components/Report1/SlideSecond_API";

const ReportView = ({ data }) => (
    <>
        <div className="w-[500px] h-[400px] border-2 border-black rounded">
            <SlideFirst data={data.SlideFirst} />
        </div>
        <div className="w-[500px] h-[400px] border-2 border-black rounded">
            <SlideSecond data={data.SlideSecond} />
        </div>
    </>
);

export default ReportView;
